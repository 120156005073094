
.Toolbar {
  font-size: 1em;
  box-sizing: border-box;
  height: 46px;
  background-color: #555555;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

/* below 477px (9 buttons x 53px), the toolbar is wrapping to a second line of buttons */
/* minimum screen width supported by the toolbar is 265px without having to manage
  a third line of buttons (design of toolbar is breaking below 265px for 8 buttons in 2 groups: 5 + (3+1)) */
@media screen and (max-width: 476px) {
  .Toolbar {
    height: 92px;
  }
}


.Toolbar button:focus {
  outline: none;
}

.Toolbar button:hover {
  cursor: pointer;
}

.Toolbar button:disabled {
  cursor: not-allowed;
}

.Toolbar .left-buttons, .Toolbar .right-buttons {
  flex: 0 0 auto;
}

.Toolbar .toolbar-btn {
  padding: 7px;
  margin: 0;
  box-sizing: border-box;
  height: 46px;
  width: 53px; /* to harmonize width with button.top */
  background-color: transparent;
  border: 1px solid #555555;
}

.Toolbar .backward, .Toolbar .forward {
  padding: 9px;
  vertical-align: top; /* to fix misalignement after padding re-calculation */
}

.Toolbar .top {
  /* button width for top face is limiting/defining svg size, when
  for left and right it's the button height. Here width 53 px is calculated
  for same face size when height is 46px (borders counted) */
  width: 53px;
}

.Toolbar .toolbar-btn:hover polygon, .Toolbar .toolbar-btn:hover path {
  fill: #ffffff;
  stroke: #ffffff;
}

.Toolbar .toolbar-btn.active {
  background-color: #323232;
  border: 1px solid #2b2b2b;
}

.Toolbar .toolbar-btn.active polygon, .Toolbar .toolbar-btn.active path {
  fill: #ffffff;
  stroke: #ffffff;
}

.Toolbar .toolbar-btn:disabled polygon, .Toolbar .toolbar-btn:disabled path {
  fill: #7c7c7c;
  stroke: #7c7c7c;
}

.Toolbar svg, .Toolbar img {
  height: 100%;
  width: 100%;
}

.Toolbar polygon, .Toolbar path {
  fill: #dadada;
  stroke: #dadada;
  /* can be overwritten locally in svg inline styles,
  wherever stroke are part of the design */
  stroke-width: 0;
}

/* .placeholder-for-future-button {
  display: inline-block;
  width: 53px;
} */
