
.App {
  font-size: 1em;
  color: #282828;
}

/* minimum screen width supported by the header is 287 px, below this the
  info-btn will wrongly wrap into the toolbar */
.App-header {
  height: 61px;
  background: linear-gradient(0deg, #181818 40%, #282828);
  border-top: 1px solid #181818;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.App-logo {
  height: 36px;
  margin: 12px 22px;
}

.App a {
  text-decoration: none;
}

.App a:hover {
  text-decoration: underline;
}

.info-btn {
  height: 36px;
  padding: 0;
  margin: 12px 22px;
  background-color: transparent;
  border: none;
}

.App-header button:focus {
  outline: none;
}

.info-btn svg {
  padding: 4px;
  height: 28px;
  width: 28px;
  fill: #dadada;
}

.info-btn:hover {
  cursor: pointer;
}

.info-btn:hover svg {
  fill: #ffffff;
}
