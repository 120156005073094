
.palette-wrapper {
  position: absolute;
}

.palette-dropdown {
  position: relative;
  box-sizing: border-box;
  width: 253px;
  right: 200px;
  padding: 20px;
  border: 2px solid #aaaaaa;
  background-color: #f6f6f6;
}

.palette-dropdown .color-input input {
  margin-top: 14px;
  padding: 6px;
  font-size: 1em;
  border: 1px solid #cccccc;
  width: 195px;
}

.palette-dropdown .color-input input:focus {
  outline: none;
}

.color-syntax-error-message {
  color: #cc0000;
  margin-top: 4px;
  font-size: 0.9em;
}

.palette-dropdown .color-change-checkbox {
  margin: 20px 0 16px 0;
}

/* color targets rules */

.palette-dropdown .color-targets {
  width: 209px;
  background-color: white;
}

.palette-dropdown .faces-wrapper {
  background: linear-gradient(#f6f6f6, #f6f6f6 20%, transparent 40%, transparent);
}

.palette-dropdown .faces-wrapper button {
  padding: 4px 1px 4px 1px;
  width: 69px;
  border: none;
  background-color: transparent;
  overflow-wrap: break-word;
  color: #282828;
}

/* width 67px > height defined as 58px to conserve same proportion between
right/left and top faces */
.palette-dropdown .faces-wrapper button svg {
  display: block;
  height: 58px;
}

.palette-dropdown .faces-wrapper button .selected-color-text {
  margin-top: 6px;
}

/* we move the cursor changes from the faces' buttons to the faces' polygons,
where are also the click events */
.palette-dropdown .faces-wrapper button:hover,
.palette-dropdown .faces-wrapper button:disabled {
  cursor: default;
}
.palette-dropdown .faces-wrapper button:disabled polygon:hover {
  cursor: not-allowed;
}
.palette-dropdown .faces-wrapper button polygon:hover {
  cursor: pointer;
}

.palette-dropdown button.background-target {
  width: 100%;
  min-height: 34px;
  border: none;
  background-color: transparent;
  overflow-wrap: break-word;
  color: #282828;
}

.palette-dropdown .color-list {
  margin-top: 22px;
  border-top: 1px solid #aaaaaa;
  overflow-y: scroll;
}

/* color list rules */

.palette-dropdown .color-list p {
  margin-top: 6px;
  margin-bottom: 12px;
  width: 100%;
  text-align: center;
}

.palette-dropdown .color-list ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 0;
  max-height: 90px;
}

.palette-dropdown .color-list li {
  box-sizing: border-box;
  flex: 0 0 69px;
  width: 69px;
  font-size: 0.7em;
  padding: 3px;
  margin-bottom: 6px;
  overflow-wrap: break-word;
  text-align: center;
}

.palette-dropdown .color-list li .color-sample {
  margin-left: 16px;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  border: 1px solid #ffffff;
  border-radius: 11px;
  margin-bottom: 1px;
}

.palette-dropdown .color-list li .color-sample:hover {
  cursor: pointer;
}
